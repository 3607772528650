import React from 'react';
import Layout from '../components/layout/Layout';
import companyInfos from '../data/company-infos';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';
import CompanyInfoItem from '../components/CompanyInfoItem';
import { Helmet } from 'react-helmet';

const Index = ({data}) => (
  <Layout>
    <Helmet>
      <title>Daten-Strom-GP | Planung und Installation von Datennetzen sowie Elektroanlagen</title>
    </Helmet>
    <section className="pt-5 md:pt-20">
      <div className="container mx-auto px-8 lg:flex">
        <div className="lg:w-1/2 mb-8 lg:mb-0">
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
        <div className="text-center lg:text-left lg:w-1/2 lg:pl-10 flex flex-col items-stretch justify-around">
          {companyInfos.map(info => (
            <CompanyInfoItem info={info} />
          ))}
        </div>
      </div>
    </section>
    <section id="services" className="py-10 lg:pb-20 lg:pt-20">
      <div className="container mx-auto text-center text-xl">
        <h2 className="text-3xl lg:text-5xl font-semibold">Angebotene Leistungen</h2>
        <p className="mx-5 md:mx-20 my-5">Anschluss von Kupferdatenleitungen, Messung der Übertragungsstrecke nach den jeweils gültigen Normen sowie die Störungssuche in der Übertragungsstrecke.</p>
        <p className="mx-5 md:mx-20 my-5">Anschluss von Glasfaserkabeln, Messung (Dämpfung und OTDR) sowie Störungssuche.</p>
        <p className="mx-5 md:mx-20 my-5">Elektroinstallationsarbeiten können ebenfalls ausgeführt werden.</p>
        <p className="mx-5 md:mx-20 my-5">Gern beraten wir Sie bei der Umsetzung Ihrer Projekte.</p>
      </div>
    </section>
  </Layout>
);

export const query = graphql`
query LogoFull {
  file(relativePath: {eq: "logo-full.png"}) {
    childImageSharp {
      fluid {
        ...GatsbyImageSharpFluid
      }
    }
  }
}
`

export default Index;
