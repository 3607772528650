import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function CompanyInfoItem ({ info }) {
  return (
    <div className="inline-flex flex-col lg:flex-row items-center justify-center lg:justify-start">
      <div className="text-white p-4 inline-flex items-center justify-center shadow-lg rounded-full bg-red-700">
        <FontAwesomeIcon icon={info.icon} size="2x" />
      </div>
      <p className="mb-8 lg:mb-0 float-left text-3xl sm:text-4xl lg:pl-10">
        {info.content ? (
          <span>
            {info.content[0]}<br />
            {info.content[1]}<br />
            {info.content[2]}
          </span>
        ) : (
          <a href={info.url}>{info.label}</a>
        )}
      </p>
    </div>
  );
}
